import 'alpinejs'

// Lazyload
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

//Cookie
// import CookieConsent from "./components/cookie-consent";
// document.addEventListener('DOMContentLoaded', function () {
//     new CookieConsent().init();
// });

//SmoothScroll
//<a data-scroll href=...>
import SmoothScroll from "smooth-scroll";
var scroll = new SmoothScroll('a[data-scroll]', {
  speed: 300,       //speed
  offset: 450       //Abstand
});

// Nav
const nav  = document.getElementById("nav-offcanvas");
const navToggles = document.querySelectorAll('.js-toggle-nav');
const navCloses = document.querySelectorAll('.js-close-nav');

navToggles.forEach(toggle => toggle.addEventListener("click", event => {
  nav.classList.toggle('offcanvas-open');
}));

navCloses.forEach(toggle => toggle.addEventListener("click", event => {
  nav.classList.remove('offcanvas-open');
}));



//Scroll Top Button
const toTopButton = document.getElementById("myTopBtn");
window.addEventListener('scroll', (event) =>  { 
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    toTopButton.style.marginBottom = "6rem";
  } else {
    toTopButton.style.marginBottom = "-6rem";
  }
});



var filterbtn = document.querySelectorAll(".filterbtn");
for (var i=0; i<filterbtn.length; i++) {
	filterbtn[i].addEventListener ('click', function (eve) {
		// Der Index i kann hier nicht benutzt werden
		//console.log ("this " +  this);
		//console.log ("event " +  eve);
		document.getElementById("suche").submit();

	});
}


const showauctionsbutton  = document.getElementById("showauctionsbutton");
var auctions = document.getElementsByClassName('auctions');
if(showauctionsbutton) {
	showauctionsbutton.addEventListener ('click', function (e) {
		for (var i=0; i<auctions.length; i++) {
			auctions[i].classList.remove("hidden");
		}
		this.classList.add("hidden");
		return false;
	});
}



